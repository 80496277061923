/*
 * Themes for each client.
 */

@import 'theme-variables';
@import 'global-variables';

// Navbar

.navbar {
}
.modal-backdrop {
    z-index: 1050 !important;
}

.mobile-menu {
    button {
        text-transform: uppercase;
        font-weight: 600;
    }
    .modal-body {
        @media (min-width: 480px) and (max-width: 991px) {
            display: flex;
            justify-content: center;
        }
    }
    .navbar-nav .dropdown-menu {
        background-color: $mobile-navbar-bg-color;
        display: block;
        button {
            color: $mobile-navbar-svg-color;
        }
    }
}

// Footer

.o-footer {
    p {
        font-size: 1rem;
    }
    .scalio-content {
        display: flex;
        align-items: center;
        @media (max-width: $tablet) {
            flex-direction: column;
        }
    }
    .copyright {
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: $tablet) {
                flex-direction: column;
                p {
                    margin-bottom: 2rem !important;
                }
            }
        }
    }
}

//Buttons

.btn {
    &:hover {
        transform: scale(1.1);
        transition: all 0.3s ease;
        background-color: map-get($theme-colors, 'primary') !important;
        color: $white !important;
    }
}

.btn-outline-primary {
    border-color: map-get($theme-colors, 'primary') !important;
}

// Typo

p {
    font-weight: 300;
    @media (min-width: $desktop) {
        font-size: calc(20px + (32 - 20) * ((100vw - 1200px) / (3840 - 1200)));
    }
}

// Home Page

.home-page {
    padding-top: 3rem;

    .header-section {
        .o-bg-image-section {
            min-height: 760px;
            @media (max-width: $mobile) {
                min-height: 950px;
            }
            @media (min-width: $desktop-xl) {
                min-height: 1200px;
            }
        }
        .overlay {
            background-image: url(../assets/img/grainpattern.png) !important;
        }
    }

    .founders-pass {
        .content {
            height: 100% !important;
        }
    }

    .cards-section {
        $height: 26rem;
        .a-image {
            .background-img-section {
                height: $height !important;
            }
        }
        .card-image {
            background: linear-gradient(#121721 0 0) padding-box,
                linear-gradient(to bottom, #121721, #661dcf, #3db0ff, #121721) border-box;
            border: 2px solid transparent;
            display: inline-block;
            height: $height !important;
            @media (max-width: $tablet) {
                width: 100%;
            }
        }
        .card-item {
            .card-image-title {
                img {
                    max-width: 15.75rem;
                    padding-left: 0.625rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .dream-team {
        .content {
            height: 100% !important;
            padding: 20rem 0 5rem;
            @media (max-width: $mobile) {
                padding: 5rem 0;
            }
        }
    }
}
