@import 'breakpoints';

/*------------------------------------*\
    Typography
\*------------------------------------*/

@font-face {
    font-family: 'OpenSans';
    font-style: thin;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype'),
        url('/assets/fonts/OpenSans/OpenSans-Light.woff') format('woff'),
        url('/assets/fonts/OpenSans/OpenSans-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype'),
        url('/assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
        url('/assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'OpenSans';
    font-style: semiBold;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/OpenSans/OpenSans-Semibold.ttf') format('truetype'),
        url('/assets/fonts/OpenSans/OpenSans-Semibold.woff') format('woff'),
        url('/assets/fonts/OpenSans/OpenSans-Semibold.woff2') format('woff2');
}

@font-face {
    font-family: 'TTNorms';
    font-style: bold;
    font-weight: 600;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-ExtraBold.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-ExtraBold.woff') format('woff'),
        url('/assets/fonts/TTNorms/TTNorms-ExtraBold.woff2') format('woff2');
}

@font-face {
    font-family: 'TTNorms';
    font-style: heavy;
    font-weight: 800;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-Heavy.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-Heavy.woff') format('woff'),
        url('/assets/fonts/TTNorms/TTNorms-Heavy.woff2') format('woff2');
}
